import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBcg15WRttUzXXTZ1w5ekUxvVqsaXfPR80",
  authDomain: "touristar-e32a4.firebaseapp.com",
  projectId: "touristar-e32a4",
  storageBucket: "touristar-e32a4.appspot.com",
  messagingSenderId: "402389795379",
  appId: "1:402389795379:web:450dfdcc2258c4e085d946",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
