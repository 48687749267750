export function MainCta(props: {
  title: string;
  isMobile: boolean;
  onClick: Function;
}) {
  const { title, isMobile, onClick } = props;
  const alignment = isMobile ? "self-center" : "";
  const ctaWidth = isMobile ? "w-[80%]" : "w-[15rem]";
  return (
    <button
      onClick={() => onClick()}
      className={`cursor-pointer ${ctaWidth} p-3 text-header-bg text-[1.6rem] bg-white rounded-xl flex flex-row justify-center font-black ${alignment}`}
    >
      <span>{title}</span>
    </button>
  );
}
