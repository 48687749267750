export function ImageWithDescription(props: {
  image: string;
  title: string;
  body: string;
  isMobile: boolean;
}) {
  const { image, title, body, isMobile } = props;

  const layout = isMobile ? "flex-col items-start" : "flex-row";

  return (
    <div className={`flex ${layout} mb-12`}>
      <img src={image} className="h-[10rem] mr-9 object-contain"></img>
      {isMobile ? <div className="h-6"></div> : <></>}
      <div className="flex flex-col items-start justify-center text-white">
        <span className="text-[1.8rem] font-bold">{title}</span>
        <span className="text-[1.1rem]">{body}</span>
      </div>
    </div>
  );
}
