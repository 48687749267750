import { MainCta } from "./MainCta";

export function Header(props: {
  header: string;
  body: string;
  ctaText: string;
  image: string;
  onClick: Function;
  isMobile: boolean;
}) {
  const { header, body, ctaText, image, onClick, isMobile } = props;

  const titleTextSize = isMobile ? "text-[1.7rem]" : "text-[3rem]";
  const layout = isMobile
    ? "flex-col"
    : "flex-row justify-between items-center";
  const paddingX = isMobile ? "p-[8%]" : "p-[20%]";
  const textWidth = isMobile ? "" : "w-[60%]";
  const subtitleTextSize = isMobile ? "text-[1.2rem]" : "text-[1.5rem]";

  return (
    <div
      className={`w-full bg-header-bg flex flex-col text-white ${paddingX} py-12`}
    >
      <div className={`h-full w-full flex ${layout}`}>
        <div className={`flex flex-col ${textWidth}`}>
          <span className={`${titleTextSize} font-bold mb-3`}>{header}</span>
          <span className={`${subtitleTextSize} mb-9`}>{body}</span>
          <MainCta
            title={ctaText}
            isMobile={isMobile}
            onClick={() => onClick()}
          />
          {isMobile ? <div className="h-12"></div> : <></>}
        </div>
        <img src={image} className="h-[30rem] object-contain"></img>
      </div>
    </div>
  );
}
