import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

export function AppDialog(props: {
  title: string;
  subtitle: string;
  open: boolean;
  showDialog: Function;
}) {
  const { title, open, subtitle, showDialog } = props;
  const [value, setValue] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  return (
    <Dialog
      open={open}
      onClose={() => showDialog(false)}
      PaperProps={{
        sx: { position: "fixed", top: 10, left: 10, right: 10, m: 2 },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
          {subtitle}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Enter your email (we'll never send you junk)"
          type="email"
          fullWidth
          variant="standard"
          onChange={(e) => setValue(e.currentTarget.value)}
          error={error}
          helperText={error ? "Enter a valid email" : null}
        />
      </DialogContent>
      <DialogActions>
        {!submitted ? (
          <Button onClick={() => _submit()}>Submit</Button>
        ) : (
          <span className="p-3">Thanks for submitting! We'll be in touch.</span>
        )}
      </DialogActions>
    </Dialog>
  );

  async function _submit() {
    if (!value || !validateEmail(value)) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    await addDoc(collection(db, "submittedEmails"), { email: value });
    setSubmitted(true);
  }

  function validateEmail(email: string) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return false;
    }
    return true;
  }
}
