import { useTheme } from "@mui/material";
import { Header } from "./components/Header";
import { ImageWithDescription } from "./components/ImageWithDescription";
import { PreHeader } from "./components/PreHeader";
import { useState } from "react";
import { AppDialog } from "./components/AppDialog";
import { useMediaQuery } from "react-responsive";
const headerImage = require("./images/itinerary.png");
const ai = require("./images/ai.png");
const notifications = require("./images/notifications.png");
const buddies = require("./images/buddies.png");
const documents = require("./images/documents.png");
const money = require("./images/money.png");
const logo = require("./images/logo.png");

function App() {
  const theme = useTheme();
  const [open, showDialog] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const paddingX = isMobile ? "p-[8%]" : "p-[20%]";

  return (
    <div className="w-full h-full flex flex-col">
      <PreHeader title="Touristar" image={logo} isMobile={isMobile} />
      <Header
        header="Effortless Travel Planning, AI-Powered Recommendations, Memorable Journeys."
        body="Manage your trip itinerary, flight bookings, tickets and expenses in a smarter way – all from one single app."
        ctaText="Get it for free 📲"
        image={headerImage}
        onClick={() => showDialog(!open)}
        isMobile={isMobile}
      />
      <div className={`w-full flex flex-col bg-black ${paddingX} py-12`}>
        <ImageWithDescription
          title="From idea to itinerary"
          body="Enter a destination, and our advanced travel AI engine will whip up a unique itinerary, including restaurants and museums – tailored just for your trip."
          image={ai}
          isMobile={isMobile}
        />
        <ImageWithDescription
          title="Travel with your buddies"
          body="You can invite your friends to the Touristar app to view and edit the itinerary you created."
          image={buddies}
          isMobile={isMobile}
        />
        <ImageWithDescription
          title="Track expenses"
          body="Never worry about being owed for dinners out or sightseeing tours. Add all your trip expenses, and ask your friends to transfer what they owe directly within the app."
          image={money}
          isMobile={isMobile}
        />
        <ImageWithDescription
          title="Get notified"
          body="Never miss a gate announcement, flight cancellation or delay. We will notify you as soon as something happens!"
          image={notifications}
          isMobile={isMobile}
        />
        <ImageWithDescription
          title="Upload your documents"
          body="Dinner reservations, theatre tickets, car hire confirmations. Keep all your travel documents in one single place, so you can access them easily."
          image={documents}
          isMobile={isMobile}
        />
        <AppDialog
          open={open}
          showDialog={showDialog}
          title="We're still crossing the t's and dotting the i's ✈️"
          subtitle="Submit your email – we'll let you know when the app is ready."
        />
      </div>
    </div>
  );
}

export default App;
