export function PreHeader(props: {
  title: string;
  image: string;
  isMobile: boolean;
}) {
  const { title, image, isMobile } = props;

  const paddingX = isMobile ? "px-[8%]" : "px-[20%]";
  const imageHeight = isMobile ? "h-[2.5rem]" : "h-[3rem]";
  const textSize = isMobile ? "text-[1.8rem]" : "text-[2rem]";

  return (
    <div className={`bg-black h-[6rem] flex flex-row ${paddingX} items-center`}>
      <img src={image} className={`${imageHeight} mr-6`}></img>
      <span className={`${textSize} font-bold text-white`}>{title}</span>
    </div>
  );
}
